import Vue from "vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store/index";

const apiAttachments = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.coreApi.url}/attachments`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

axiosRetry(apiAttachments, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

apiAttachments.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
  }
  return configuration;
});

apiAttachments.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");

      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }

    return Promise.reject(error);
  }
);

export default {
  add(blob) {
    const formData = new FormData();
    formData.append("file", blob, blob.name);

    return apiAttachments.post("/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  downlod(blobId, channel = "") {
    return apiAttachments.get(`/${blobId}/${channel}`, {
      responseType: "blob",
    });
  },
};
